import React from 'react';
import ScoreArc from './ScoreArc';

type Props = {
  score: number,
  size: "small" | "large"
};

const ImpactScore = (props: Props) => {

  let fontSize = props.score === 0 ? 'text-base' : 'text-2xl';
  let arcSize = 25;
  let margin = 'ml-1 mt-1';
  if (props.size && props.size === 'large') {
    fontSize = props.score === 0 ? 'text-3xl' : 'text-5xl';
    arcSize = 35;
    margin = 'ml-2 mt-2';
  }

  return (
    <div className='flex items-center'>
      <ScoreArc
        size={arcSize}
        score={props.score}
      />
      <div data-cy="impact-score" className={`${fontSize} ${margin} font-bold ${props.score >= 0 ? 'text-orange' : 'text-red'}`}>
        { props.score === 0 ? 'NA' : props.score }
      </div>
    </div>
  );
};

export default ImpactScore;
