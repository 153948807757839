import React from 'react';
import * as DateUtil from '../utilities/date';
import { NegativeIssue } from '../types/Issues';

type Props = {
  stockIssueDetails: NegativeIssue[]
};

const NegativeIssueDetails = (props: Props) => {
  return (
    <div className='bg-black/5 mt-2 mb-6 px-6'>
      {
        props.stockIssueDetails.map((issueDetail: NegativeIssue, index: number) => {
          return (
            <div className={`geometric border-teal-700/20 py-6 ${index !== props.stockIssueDetails.length - 1 ? 'border-b' : ''}`} key={index}>
              <div className='text-xs font-bold bg-blue text-white w-fit rounded px-2 py-1'>{ DateUtil.formatToMonthDayYear(issueDetail.date) }</div>
              <div className='text-base mt-2'>{ issueDetail.description }</div>
              <div className='text-base font-bold mt-2'>{ issueDetail.sourceName }</div>
              <a className='text-base font-bold text-blue mt-2' href={ issueDetail.sourceLink }>{ issueDetail.sourceLink }</a>
            </div>
          );
        })
      }
    </div>
  );
};

export default NegativeIssueDetails;