import React from 'react';

const Footer = () => {
  return (
    <div className='w-full pb-14'>
      <div className="flex justify-between fixed bottom-0 w-full py-4 px-5 bg-slate-100 border-t border-slate-300">
        <div className='flex flex-wrap'>
          <a className="text-xs mr-2 text-slate-500" href="https://legacy.inspireinsight.com/terms-of-service">
            Terms
          </a>
          <a className="text-xs mr-2 text-slate-500" href="https://legacy.inspireinsight.com/privacy-policy">
            Privacy
          </a>
          <a className="text-xs mr-2 text-slate-500" href="https://legacy.inspireinsight.com/help-and-support">
            Help &amp; Support
          </a>
          <a className="text-xs mr-2 text-slate-500" href="https://legacy.inspireinsight.com/screening-categories">
            Screening Categories
          </a>
          <a className="text-xs mr-2 text-slate-500" href="/score-updates">
            Score Updates
          </a>
        </div>
        <div className="text-xs mr-2 text-slate-500">
          © Copyright - Inspire Insight
        </div>
      </div>
    </div>
  );
};

export default Footer;
