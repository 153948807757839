import React from 'react';
import { useEffect, useState } from 'react';
import Stock from '../types/Stock';
import axios from 'axios';
import SearchResult from './SearchResult';
import spinner from '../images/dual-ring-loading-grey.svg';
import search from '../images/search-icon-black.svg';

const SearchBar = () => {

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([] as Stock[]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searching, setSearching] = useState(false);

  useEffect(() => {
    if (searchTerm) {
      setSearching(true);
      const delayDebounceFn = setTimeout(async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/search/` + searchTerm);
        setSearchResults(response.data as Stock[]);
        setSearching(false);
      }, 500);
  
      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchTerm]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onBlur = (event: any) => {
    if (!event.relatedTarget?.id.includes('search-result')) {
      setTimeout(() => {setShowSearchResults(false);}, 250);
    }
  };

  return (
    <div className='flex border-slate-300 border relative'>
      <input
        type="text"
        data-cy="search-bar"
        placeholder="Search by ticker, company, or fund name"
        className="rounded text-black placeholder-black py-4 pl-5 pr-14 w-full hover:shadow-md focus:shadow-md outline-0 transition-shadow duration-300 ease-in-out"
        onChange={(e) => setSearchTerm(e.target.value)}
        onFocus={() => setShowSearchResults(true)}
        onBlur={(event) => onBlur(event)}
      />
      {
        !searching &&
        <img src={search} className="w-5 absolute right-4 top-4" alt="search" />
      }
      {
        searching &&
        <img src={spinner} className="w-14 absolute right-0" alt="spinner" />
      }
      {
        showSearchResults && searchTerm &&
        <div className="border border-slate-300 rounded absolute bg-white w-full top-14">
          {
            searchResults.map((stock: Stock, index: number) => {
              return <SearchResult key={ index } {...stock} />;
            })
          }
        </div>
      }
    </div>
  );
};

export default SearchBar;