import * as d3 from 'd3';
import Constants from '../constants';

const trig = Math.PI / 4;
const innerArcThreshold = 0;
const middleArcThreshold = 33;
const outerArcThreshold = 66;

const getInnerArcEndAngle = (absoluteScore: number) => {
  const portion: number = Math.min(absoluteScore, middleArcThreshold) / middleArcThreshold;
  const maxAngle: number = 2 + (2 * portion);
  return maxAngle * trig;
};

const getMiddleArcEndAngle = (absoluteScore: number) => {
  const proportion: number = absoluteScore - middleArcThreshold;
  const max = middleArcThreshold - innerArcThreshold;
  const portion: number = Math.min(proportion, max) / max;
  const maxAngle: number = 2 + (2 * portion);
  return maxAngle * trig;
};

const getOuterArcEndAngle = (absoluteScore: number) => {
  const proportion: number = absoluteScore - outerArcThreshold;
  const max = 100 - outerArcThreshold;
  const portion: number = Math.min(proportion, max) / max;
  const maxAngle: number = 2 + (2 * portion);
  return maxAngle * trig;
};

export const renderArc = (score: number, size: number, containerID: string) => {
  d3.select('#' + containerID)
    .selectAll('*')
    .remove();

  const angleZero: number = 2 * trig;
  const angleFinal: number = 4 * trig;
  const absoluteScore: number = Math.abs(score);
  const growth: number = (size - 5) / 3;
  const strokeWidth: number = growth / 2;
  const isNeg: boolean = score * 1 < 0;
  const color: string = isNeg ? Constants.colors.red : Constants.colors.blue;

  const svg = d3
    .select("[id='" + containerID + "']")
    .append('svg')
    .attr('width', size)
    .attr('height', size);

  const g = svg.append('g');

  // calculate bg (grey) arcs
  const arcInnerBG: any = d3 // eslint-disable-line @typescript-eslint/no-explicit-any
    .arc()
    .innerRadius(growth)
    .outerRadius(growth + strokeWidth)
    .startAngle(angleZero)
    .endAngle(angleFinal);

  const arcMiddleBG: any = d3 // eslint-disable-line @typescript-eslint/no-explicit-any
    .arc()
    .innerRadius(growth * 2)
    .outerRadius(growth * 2 + strokeWidth)
    .startAngle(angleZero)
    .endAngle(angleFinal);

  const arcOuterBG: any = d3 // eslint-disable-line @typescript-eslint/no-explicit-any
    .arc()
    .innerRadius(growth * 3)
    .outerRadius(growth * 3 + strokeWidth)
    .startAngle(angleZero)
    .endAngle(angleFinal);

  // calculate positive/negative arcs
  const arcInner: any = d3 // eslint-disable-line @typescript-eslint/no-explicit-any
    .arc()
    .innerRadius(growth)
    .outerRadius(growth + strokeWidth)
    .startAngle(angleZero)
    .endAngle(getInnerArcEndAngle(absoluteScore));

  const arcMiddle: any = d3 // eslint-disable-line @typescript-eslint/no-explicit-any
    .arc()
    .innerRadius(growth * 2)
    .outerRadius(growth * 2 + strokeWidth)
    .startAngle(angleZero)
    .endAngle(getMiddleArcEndAngle(absoluteScore));

  const arcOuter: any = d3 // eslint-disable-line @typescript-eslint/no-explicit-any
    .arc()
    .innerRadius(growth * 3)
    .outerRadius(growth * 3 + strokeWidth)
    .startAngle(angleZero)
    .endAngle(getOuterArcEndAngle(absoluteScore));

  // draw bg (grey) arcs
  g.append('path')
    .attr('d', arcInnerBG)
    .attr('fill', Constants.colors.grey)
    .attr('transform', `translate(${size},${size}) scale(-1,-1)`);

  g.append('path')
    .attr('d', arcMiddleBG)
    .attr('fill', Constants.colors.grey)
    .attr('transform', `translate(${size},${size}) scale(-1,-1)`);

  g.append('path')
    .attr('d', arcOuterBG)
    .attr('fill', Constants.colors.grey)
    .attr('transform', `translate(${size},${size}) scale(-1,-1)`);

  // draw score (colored) arcs
  if (absoluteScore >= innerArcThreshold)
    g.append('path')
      .attr('d', arcInner)
      .attr('fill', color)
      .attr('transform', `translate(${size},${size}) scale(-1,-1)`);

  if (absoluteScore >= middleArcThreshold)
    g.append('path')
      .attr('d', arcMiddle)
      .attr('fill', color)
      .attr('transform', `translate(${size},${size}) scale(-1,-1)`);

  if (absoluteScore >= outerArcThreshold)
    g.append('path')
      .attr('d', arcOuter)
      .attr('fill', color)
      .attr('transform', `translate(${size},${size}) scale(-1,-1)`);
};