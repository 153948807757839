import React from 'react';

type Props = {
    title: string;
    url: string;
    linkText: string;
}

const Banner = (props: Props) => {
    if (!props.title || !props.url || !props.linkText) {
        return null;
    }

    return (
        <div className="w-full text-center bg-blue text-white py-2 px-4 md:px-10">
            {props.title}: &nbsp;
            <a className="font-bold" target="_blank" rel="noreferrer" href={props.url}>{props.linkText}</a>
        </div>
    );
};

export default Banner;
