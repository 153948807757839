import React from 'react';
import ImpactScore from './ImpactScore';
import Stock from '../types/Stock';

type Props = Stock;

const MiniTicker = (props: Props) => {
  return (
    <a href={ `/${props.ticker}/${props.country}` } data-cy='ticker' className="text-sm flex items-center border-b border-slate-300 min-h-[3rem]">
      <div className="basis-1/6 px-2 font-bold condensed text-black/85">{ props.ticker }</div>
      <div className="basis-1/2 px-2 condensed text-black/85">{ props.name.length > 25 ? props.name.substring(0, 25) + "..." : props.name }</div>
      <div className='basis-1/3 px-2 flex justify-end'>
        <ImpactScore score={props.score} size="small" />
      </div>
    </a>
  );
};

export default MiniTicker;
