const Constants = {
  colors: {
    blue: '#2c8aaf',
    orange: '#d27732',
    red: '#bd425a',
    black: '#0b192c',
    grey: '#c2d5e4',
}
};

export default Constants;