import React from 'react';
import * as DateUtil from '../utilities/date';
import { PositiveIssue } from '../types/Issues';

type Props = {
  stockIssueDetails: PositiveIssue[],
  issueName: string
};

const PositiveIssueDetails = (props: Props) => {
  return (
    <div className='bg-black/5 mt-2 mb-6 px-6'>
      {
        props.stockIssueDetails.map((issueDetail: PositiveIssue, index: number) => {
          return (
            <div className={`geometric border-teal-700/20 py-6 ${index !== props.stockIssueDetails.length - 1 ? 'border-b' : ''}`} key={index}>
              <div className='text-xs font-bold bg-blue text-white w-fit rounded px-2 py-1'>{ DateUtil.formatToMonthDayYear(issueDetail.date) }</div>
              <div className='text-base mt-2'>
                This company ranks { issueDetail.materialityScore } in its { props.issueName } (Best In Class) industry peer group percentile
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default PositiveIssueDetails;