import React from 'react';
import ImpactScore from './ImpactScore';
import Stock from '../types/Stock';

type Props = Stock;

const SearchResults = (props: Props) => {
  return (
    <a href={ `/${props.ticker}/${props.country}` } id={`search-result-${props.ticker}-${props.country}`} data-cy="search-result" className="text-md flex items-center border-b border-slate-300 min-h-[3rem] px-2 hover:bg-zinc-100">
      <div className="basis-1/6 font-bold condensed text-black/85">{ props.ticker }</div>
      <div className="basis-1/2 pl-2 condensed text-black/85">{ props.name }</div>
      <div className='basis-1/3 flex justify-end'>
        <ImpactScore score={props.score} size="small" />
      </div>
    </a>
  );
};

export default SearchResults;
