import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import Instrument from './pages/instrument';
import ScoreUpdates from './pages/score-updates';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/score-updates" element={<ScoreUpdates />} />
        <Route path="/*" element={<Instrument />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
