import React, { useState } from 'react';
import PositiveIssueDetails from './PositiveIssueDetails';
import { PositiveIssueRecord } from '../types/Issues';

type Props = {
  issueDetails: PositiveIssueRecord,
  issueName: string
};

const FundPositiveIssueDetails = (props: Props) => {

  const [selectedStock, setSelectedStock] = useState('');
  const [viewAll, setViewAll] = useState(false);

  const tickerListLimit = 10;

  return (
    <div>
      <div className='flex flex-wrap mb-2'>
        {
          Object.keys(props.issueDetails)
            .slice(0, viewAll ? undefined : tickerListLimit)
            .map((ticker: string, index: number) => {
            return (
              <div key={ index } onClick={() => setSelectedStock(selectedStock ? '' : ticker)} className={`recta-cond font-bold w-fit text-base rounded py-1 px-2 ${selectedStock === ticker ? 'text-white bg-blue' : 'text-blue bg-slate-300'} cursor-pointer hover:text-white hover:bg-blue transition-colors duration-300 ease-in-out mr-3 mb-3`}>
                { ticker }
              </div>
            );
          })
        }
        {
          Object.keys(props.issueDetails).length > tickerListLimit &&
          <div onClick={() => setViewAll(!viewAll)} className={`recta-cond font-bold w-fit text-base rounded py-1 px-2 text-blue bg-slate-300 cursor-pointer hover:text-white hover:bg-blue transition-colors duration-300 ease-in-out mb-3`}>
            {viewAll ? 'View Less' : 'View More'}
          </div>
        }
      </div>
      {
        selectedStock !== '' &&
        <PositiveIssueDetails stockIssueDetails={ props.issueDetails[selectedStock] } issueName={ props.issueName } />
      }
    </div>
  );
};

export default FundPositiveIssueDetails;