import React from 'react';
import NegativeIssueDetails from './NegativeIssueDetails';
import FundNegativeIssueDetails from './FundNegativeIssueDetails';
import { NegativeIssueRecord } from '../types/Issues';

type Props = {
  fund: boolean,
  issueName: string,
  issueDetails: NegativeIssueRecord,
  selectedIssue: string,
  onClick: () => void
};

const NegativeIssueRow = (props: Props) => {
  return (
    <div data-cy="issue-row" className='border-b border-zinc-200 px-10'>
      <div onClick={props.onClick} key={ props.issueName } className='flex pt-5 pb-3 cursor-pointer'>
        <div className='flex justify-center w-5 h-5 shrink-0 bg-red text-white rounded-full mr-2'>
          <svg className="w-2.5" viewBox="0 0 448 512" aria-hidden="true" focusable="false" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>
        </div>
        <div className='recta-med text-sm font-semibold hover:text-blue transition-colors duration-300 ease-in-out'>
          { props.issueName.toUpperCase() }
        </div>
      </div>
      {
        !props.fund &&
        props.selectedIssue === props.issueName &&
        <NegativeIssueDetails stockIssueDetails={ props.issueDetails[Object.keys(props.issueDetails)[0]] } />
      }
      {
        props.fund &&
        props.selectedIssue === props.issueName &&
        <FundNegativeIssueDetails issueDetails={ props.issueDetails } />
      }
    </div>
  );
};

export default NegativeIssueRow;